import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import TemplateEspace from '../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const BoutiqueParamsPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => <TemplateEspace espace={espace} user={user} />;

export default requireEspace(BoutiqueParamsPage);
